import { version } from "react";

const config = {
  apiBaseUrl: 'https://retos-back.onrender.com',
/*  apiBaseUrl: window.location.hostname === 'localhost'
    ? 'http://localhost:5000'
    : 'http://192.168.100.35:5000', */
  version: "1.6"
};
  


  export default config;
    