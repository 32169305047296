import React from 'react';

function NotFound() {
  return (
    <div>
      <h1>404 - Not Found</h1>
      <p>Lo sentimos, la página que buscas no existe.</p>
    </div>
  );
}

export default NotFound;
